import blogOne from "@/assets/images/blog-1.jpg";
import blogBigImage from "@/assets/images/blog-2.jpg";

export default [
    {
        id: 1,
        img: blogOne,
        image: blogBigImage,
        date: "25 Août 2023",
        category: "Nutrition",
        title: "Mieux manger, c’est quoi ?",
        content: "",
        comments: []
    }
];
