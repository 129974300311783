<template>
  <div>
    <BlankPage title="Condition d'utilisation" :breadcrumbs="[{name:'Condition d\'utilisation', path:'/condition-of-use'}]">
      <p style="text-align: justify;">Bienvenue sur <strong>Garde Pharma.</strong></p>
      <br>
      <p style="text-align: justify;">Nous sommes heureux que vous ayez choisi d'utiliser nos produits et services (« Services »). Les présentes Conditions générales d'utilisation (« CGU ») régissent votre utilisation des Services et contiennent des informations importantes concernant nos Services et vos droits, devoirs et obligations liés à l'utilisation de nos Services. Ces CGU continueront de s'appliquer</p>
      <br><p style="text-align: justify;"><strong>Garde Pharma</strong> est une application développée par <strong>TosFabre,</strong> (« <strong>Garde Pharma</strong> », « Nous », « Notre »), vous permettant de géolocaliser les pharmacies et d'accéder à de nombreuses informations sur la santé et la liste des médicaments.</p>
      <br><p style="text-align: justify;">En utilisant <strong>Garde Pharma,</strong> vous acceptez d'être lié-e par les présentes conditions générales.</p>
      <br><p style="text-align: justify;"><strong>Garde Pharma</strong> s'engage à protéger votre vie privée et à vous offrir des services sûrs et fiables. Nous pouvons modifier ces Conditions de temps en temps. Si nous le faisons, nous publierons les modifications sur nos réseaux sociaux et dans l’application dans la rubrique dédiée et nous vous en informerons par e-mail sur demande dans un délai de 48 heures. Si vous ne souhaitez pas accepter ces modifications, vous devez cesser d'utiliser nos services. Sinon, en continuant à utiliser nos services, vous acceptez ces modifications</p>
      <br><p style="text-align: justify;">Si vous utilisez <strong>Garde Pharma</strong> à des fins commerciales, vous devez accepter les Conditions d'utilisation supplémentaires.</p>
      <br>
      <ol class="mb-2" style="text-align: justify;">
        <li><strong>1. Contenu</strong></li>
      </ol>
      <p style="text-align: justify;">Le Contenu sur l’application <strong>Garde Pharma</strong> peut inclure des textes et des images (ci-après le « Contenu »). Tout le contenu est fourni uniquement à des fins informatives et éducatives. Vous ne devez pas modifier, louer, vendre, diffuser, participer à une transaction ou à un transfert ou créer des œuvres dérivées tirées du contenu. Nous nous réservons le droit de modifier ou de supprimer le contenu à tout moment et à notre discrétion</p>
      <br><p style="text-align: justify;"><strong>Garde Pharma</strong> peut contenir des liens vers d'autres sites Web ou applications tiers qui ne sont pas sous le contrôle de <strong>Garde Pharma</strong>. Nous ne sommes pas responsables des contenus ou des pratiques de ces autres sites. Il est de votre responsabilité de prendre connaissance des conditions d'utilisation et des politiques de confidentialité des autres sites.</p>
      <br><ol class="mb-2" style="text-align: justify;" start="2">
        <li><strong>2. Garantie</strong></li>
      </ol>
      <p style="text-align: justify;">Nous ne faisons aucune déclaration ni garantie quant à l'exactitude, la fiabilité, la qualité, la santé et la sécurité des services <strong>Garde Pharma</strong>. Vous reconnaissez que <strong>Garde Pharma</strong> et son contenu sont fournis « en l’état » et «tel que disponible ».</p>
      <br>
      <ol class="mb-2" style="text-align: justify;" start="3">
        <li><strong>3. Limitations de responsabilité</strong></li>
      </ol>
      <p style="text-align: justify;">En aucun cas <strong>Garde Pharma</strong> ne saurait être tenue responsable des pertes ou dommages indirects, spéciaux, consécutifs ou accessoires découlant de l'utilisation des services, y compris notamment, sans limitation, toutes pertes ou dommages causés par la perte de données ou de bénéfices, ou la négligence de <strong>Garde Pharma</strong>.</p>
      <br><p style="text-align: justify;">En aucune circonstance, la responsabilité totale de <strong>Garde Pharma</strong> découlant de l'utilisation du service <strong>Garde Pharma</strong> ne dépassera pas le montant que vous avez payé pour l'utilisation des services.</p>
      <br>
      <ol class="mb-2" style="text-align: justify;" start="4">
        <li><strong>4. Divers</strong></li>
      </ol>
      <p style="text-align: justify;">Les présentes CGU sont régies et interprétées conformément <strong>à la loi n° 2019-014 du 29 octobre 2019 relative à la protection des données à caractère personnel de la république togolaise </strong>sans égard aux principes de conflits de lois. Si une partie de ces CGU est jugée inexécutable ou inapplicable, les dispositions restantes resteront en vigueur. Rien dans ces CGU ne crée des droits en faveur de tiers.</p>
      <br>
      <ol class="mb-2" style="text-align: justify;" start="5">
        <li><strong>5. Contact</strong></li>
      </ol>
      <p style="text-align: justify;">Si vous avez des questions au sujet de ces CGU, veuillez nous contacter par email <strong>Garde Pharma </strong>à l'adresse <a href="mailto:tosfabre@gmail.com">tosfabre@gmail.com</a></p>
    </BlankPage>
  </div>
</template>

<script>
import BlankPage from "@/components/BlankPage/index.vue";

export default {
  components: {
    BlankPage
  }
}
</script>

<style>

</style>
