<template>
  
      <div class="appie-how-it-work-area pt-10 pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="how-it-work-thumb text-center">
                        <img src="@/assets/images/how-it-work-thumb.png" alt="">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-how-it-work-content">
                        <h2 class="title">Comment utilisez Garde Pharma ?</h2>
                        <p>C'est très simple, nous travaillons pour vous améliorer la santé.</p>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="how-it-work-box">
                                    <span>1</span>
                                    <h5 class="title">Téléchargez<br/> Garde Pharma
                                    </h5>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="how-it-work-box">
                                    <span>2</span>
                                    <h5 class="title">Pas besoin<br/> d’un compte
                                    </h5>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="how-it-work-box">
                                    <span>3</span>
                                    <h5 class="title">Activez votre<br/> géolocalisation</h5>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="how-it-work-box">
                                    <span>4</span>
                                    <h5 class="title">Retrouvez les<br/> pharmacies de votre choix</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
