<template>
          <!-- Contact Start -->
        <section class="contact-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="contact--info-area">
                            <h3>Prendre contact avec nous</h3>
                            <p>
                              Vous cherchez de l'aide ? Remplissez le formulaire et commencez une nouvelle aventure.
                            </p>
                            <div class="single-info">
                                <h5>Emplacement</h5>
                                <p>
                                    <i class="fal fa-home"></i>
                                    Bè Pa de Souza, Rue animabio,<br> Lomé - Togo
                                </p>
                            </div>
                            <div class="single-info">
                                <h5>Téléphone</h5>
                                <p>
                                    <i class="fal fa-phone"></i>
                                    (+228) 93 09 15 30<br>
                                </p>
                            </div>
                            <div class="single-info">
                                <h5>Support</h5>
                                <p>
                                    <i class="fal fa-envelope"></i>
                                    info@gardepharma.com<br>
                                    contact@gardepharma.com
                                </p>
                            </div>
                            <div class="ab-social">
                                <h5>Suivez-nous</h5>
                                <a class="fac" href="#"><i class="fab fa-facebook-f"></i></a>
                                <a class="twi" href="#"><i class="fab fa-twitter"></i></a>
                                <a class="you" href="#"><i class="fab fa-youtube"></i></a>
                                <a class="lin" href="#"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="contact-form">
                            <h4>Connectez-vous</h4>
                            <p>Integer at lorem eget diam facilisis lacinia ac id massa.</p>
                            <form action="#" method="post" class="row">
                                <div class="col-md-6">
                                    <input type="text" name="f-name" placeholder="Prénom">
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="l-name" placeholder="Nom">
                                </div>
                                <div class="col-md-6">
                                    <input type="email" name="email" placeholder="Adresse Email">
                                </div>
                                <div class="col-md-6">
                                    <input type="number" name="phone" placeholder="Numéro de téléphone">
                                </div>
                                <div class="col-md-12">
                                    <input type="text" name="suject" placeholder="Sujet">
                                </div>
                                <div class="col-md-12">
                                    <textarea name="message" placeholder="Comment pouvons-nous vous aider ?"></textarea>
                                </div>
                                <div class="col-md-6">
                                    <div class="condition-check">
                                        <input id="terms-conditions" type="checkbox">
                                        <label for="terms-conditions">
                                          J'accepte les <router-link to="/condition-of-use">Condition générales d'utilisation</router-link>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 text-right">
                                    <input type="submit" name="submit" value="Envoyer Message">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Contact End -->
</template>

<script>
export default {

}
</script>

<style>

</style>
