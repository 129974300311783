<template>
  
      <!--====== APPIE SERVICES PART START ======-->
    
    <section class="appie-service-area appie-service-3-area pt-195 pb-100" id="service">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">Accessibilité des pharmacies en 1 clic</h3>
                        <p>Technologiquement sûr, Médicalement unique. </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/1.png" alt="">
                        </div>
                        <h4 class="appie-title">Easy to use</h4>
                        <p>Mucker plastered bugger all mate morish are.</p>
                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/2.png" alt="">
                        </div>
                        <h4 class="appie-title">App Development</h4>
                        <p>Mucker plastered bugger all mate morish are.</p>
                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/3.png" alt="">
                        </div>
                        <h4 class="appie-title">Fully Functional</h4>
                        <p>Mucker plastered bugger all mate morish are.</p>
                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/4.png" alt="">
                        </div>
                        <h4 class="appie-title">Secured protocol</h4>
                        <p>Mucker plastered bugger all mate morish are.</p>
                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>
