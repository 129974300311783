<template>
  <div>
          <!--====== APPIE PAGE TITLE PART START ======-->
    
    <div class="appie-page-title-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-page-title-item">
                        <h3 class="title">{{ title }}</h3>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Accueil</a></li>
                                <li v-for="(item,index) in breadcrumbs" :key="index"  class="breadcrumb-item" aria-current="page"><a :href="item.path">{{ item.name }}</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!--====== APPIE PAGE TITLE PART ENDS ======-->
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type:String,
    },
    breadcrumbs: {
      type:Array,
      default: () =>{
        return []
      }
    }
  },
}
</script>

<style>

</style>
