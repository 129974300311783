<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about-widget mt-0">
            <div class="logo">
              <a href="#"><img src="@/assets/images/logo-garde.png" height="78.6" alt="" /></a>
            </div>
            <p class="pt-1">
              Garde Pharma est une solution digitale qui vous permet de retrouver les pharmacies et celles de gardes, consulter le prix des médicaments et faire une simulation de prix de vos ordonnances.
            </p>
            <!--<a href="#">Lire Plus <i class="fal fa-arrow-right"></i></a>-->
            <div class="social mt-30">
              <ul>
                <li>
                  <a href="https://www.facebook.com/gardepharma" target="_blank"><i class="fab fa-facebook-f"></i></a>
                </li>
                <!--<li>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>-->
                <li>
                  <a href="https://www.linkedin.com/company/garde-pharma/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Liens utiles</h4>
            <ul>
              <li><router-link to="/about-us">À Propos</router-link></li>
              <li><router-link to="/service-details">Nos Services</router-link></li>
              <li><router-link to="/news">Blog</router-link></li>
              <li><router-link to="/contact">Contact</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Support</h4>
            <ul>
              <li><router-link to="/faqs">Faqs</router-link></li>
              <li><router-link to="/privacy-policy">Politique de confidentialité</router-link></li>
              <li><router-link to="/condition-of-use">Condition d'utilisation</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">Prendre contact</h4>
            <ul>
              <li>
                <a href="#"><i class="fal fa-envelope"></i> contact@gardepharma.com</a>
              </li>
              <li>
                <a href="#"><i class="fal fa-phone"></i> +(228) 93 09 15 30</a>
              </li>
              <li>
                <a href="#"><i class="fal fa-map-marker-alt"></i> 47 Rue Animabio Bè Pa de Souza, Lomé - Togo</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
            class="
              footer-copyright
              d-flex
              align-items-center
              justify-content-between
              pt-35
            "
          >
            <div class="apps-download-btn">
              <ul>
                <li>
                  <a href="#"><i class="fab fa-apple"></i> Bientôt pour iOS</a>
                </li>
                <li>
                  <a class="item-2" href="#"
                    ><i class="fab fa-google-play"></i> Télécharger pour Android</a
                  >
                </li>
              </ul>
            </div>
            <div class="copyright-text">
              <p>Copyright © 2023 TOSFABRE Dev. Tous droits réservés.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {};
</script>

<style>
</style>
