var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"contact-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"contact-form"},[_c('h4',[_vm._v("Connectez-vous")]),_c('p',[_vm._v("Integer at lorem eget diam facilisis lacinia ac id massa.")]),_c('form',{staticClass:"row",attrs:{"action":"#","method":"post"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"condition-check"},[_c('input',{attrs:{"id":"terms-conditions","type":"checkbox"}}),_c('label',{attrs:{"for":"terms-conditions"}},[_vm._v(" J'accepte les "),_c('router-link',{attrs:{"to":"/condition-of-use"}},[_vm._v("Condition générales d'utilisation")])],1)])]),_vm._m(7)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"contact--info-area"},[_c('h3',[_vm._v("Prendre contact avec nous")]),_c('p',[_vm._v(" Vous cherchez de l'aide ? Remplissez le formulaire et commencez une nouvelle aventure. ")]),_c('div',{staticClass:"single-info"},[_c('h5',[_vm._v("Emplacement")]),_c('p',[_c('i',{staticClass:"fal fa-home"}),_vm._v(" Bè Pa de Souza, Rue animabio,"),_c('br'),_vm._v(" Lomé - Togo ")])]),_c('div',{staticClass:"single-info"},[_c('h5',[_vm._v("Téléphone")]),_c('p',[_c('i',{staticClass:"fal fa-phone"}),_vm._v(" (+228) 93 09 15 30"),_c('br')])]),_c('div',{staticClass:"single-info"},[_c('h5',[_vm._v("Support")]),_c('p',[_c('i',{staticClass:"fal fa-envelope"}),_vm._v(" info@gardepharma.com"),_c('br'),_vm._v(" contact@gardepharma.com ")])]),_c('div',{staticClass:"ab-social"},[_c('h5',[_vm._v("Suivez-nous")]),_c('a',{staticClass:"fac",attrs:{"href":"#"}},[_c('i',{staticClass:"fab fa-facebook-f"})]),_c('a',{staticClass:"twi",attrs:{"href":"#"}},[_c('i',{staticClass:"fab fa-twitter"})]),_c('a',{staticClass:"you",attrs:{"href":"#"}},[_c('i',{staticClass:"fab fa-youtube"})]),_c('a',{staticClass:"lin",attrs:{"href":"#"}},[_c('i',{staticClass:"fab fa-linkedin-in"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('input',{attrs:{"type":"text","name":"f-name","placeholder":"Prénom"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('input',{attrs:{"type":"text","name":"l-name","placeholder":"Nom"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('input',{attrs:{"type":"email","name":"email","placeholder":"Adresse Email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('input',{attrs:{"type":"number","name":"phone","placeholder":"Numéro de téléphone"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('input',{attrs:{"type":"text","name":"suject","placeholder":"Sujet"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('textarea',{attrs:{"name":"message","placeholder":"Comment pouvons-nous vous aider ?"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 text-right"},[_c('input',{attrs:{"type":"submit","name":"submit","value":"Envoyer Message"}})])
}]

export { render, staticRenderFns }