<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />
    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <header-contact @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== PART ENDS ======-->

    <!--====== APPIE PAGE TITLE PART START ======-->

    <!-- reference by news -->
    <title-area title="Contact" :breadcrumbs="[{name:'Contact', path:'/contact'}]" />

    <!--====== APPIE PAGE TITLE PART ENDS ======-->

    <!-- Contact Start -->
    <contact-inputs />
    <!-- Contact End -->

    <!-- Gamps Start -->
    <div class="bisylms-map">
      <iframe
        src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;coord=39.966528,-75.158284&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland+(My%20Business%20Name)&amp;ie=UTF8&amp;t=p&amp;z=16&amp;iwloc=B&amp;output=embed"
      ></iframe>
    </div>
    <!-- Gamps Start -->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
import TitleArea from "../News/TitleArea.vue";
import ContactInputs from "./ContactInputs.vue";
import HeaderContact from "./HeaderContact.vue";
import menu from "@/components/menu";
import Contact from "@/views/Contact.vue";
export default {
  computed: {
    Contact() {
      return Contact
    }
  },
  components: {
    SidebarHomeOne,
    HeaderContact,
    TitleArea,
    ContactInputs,
    FooterHomeOne,
  },
  data() {
    return {
      sidebar: false,
      navs: menu,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>
