<template>
    <single-news :blog="blog"/>
</template>

<script>
import SingleNews from '../components/SingleNews/index'
export default {
	components: {SingleNews  },
  props: ['blog'],
  data() {
    return {

    }
  },

}
</script>

<style>

</style>
