<template>
  <div>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />

    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <header-home-seven @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== PART ENDS ======-->

    <!--====== APPIE HERO PART START ======-->

    <hero-home-seven />

    <!--====== APPIE HERO PART ENDS ======-->

    <!--====== APPIE FEATURES PART START ======-->

    <features-home-one />

    <!--====== APPIE FEATURES PART ENDS ======-->

    <!--====== APPIE COUNTER PART START ======-->

    <counter-home-two
      title="Nos chiffres parlent"
      description="L’app N°1 de géolocalisation de pharmacies au Togo"
    />

    <!--====== APPIE COUNTER PART ENDS ======-->

    <!--====== APPIE DOWNLOAD PART START ======-->

    <download-home-two
      title="Disponible"
      more_title="gratuitement"
      description="Prenez votre santé en main."
    />

    <!--====== APPIE DOWNLOAD PART ENDS ======-->

    <!--====== APPIE TEAM PART START ======-->

    <team-home-one
      title="Rencontrez les membres de notre équipe"
      description="Aux compétences différentes et complémentaires pour une vision commune : Vous apportez des solutions sanitaires satisfaisantes."
      :members="members"
    />

    <!--====== APPIE TEAM PART ENDS ======-->

    <!--====== APPIE PRICING 2 PART START ======-->

    <!--<priceing-home-two
      title="Simple pricing for Everyone"
      description="The full monty spiffing good time no biggie cack grub fantastic."
    />-->

    <!--====== APPIE PRICING 2 PART ENDS ======-->

    <!--====== APPIE FAQ PART START ======-->

    <!--<faq-home-one
      class="pb-95 pt-90"
      title="Questions fréquemment posées"
      description="Pas à pas, Garde Pharma est là pour faciliter et optimiser vos soins de santé."
    />-->

    <!--====== APPIE FAQ PART ENDS ======-->

    <!--====== APPIE BLOG PART START ======-->

    <blog-home-one
      title="Derniers articles de blog"
      description="Tout ce que vous pouvez savoir sur la Santé..."
      :blogs="blogs"
    />

    <!--====== APPIE BLOG PART ENDS ======-->

    <!--====== APPIE PROJECT PART START ======-->

    <project-home-one
      title="S'inscrire à notre newsletter"
      description="Pour recevoir nos conseils personnalisés."
    />

    <!--====== APPIE PROJECT PART ENDS ======-->

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
// import blogOne from "@/assets/images/blog-1.jpg";
// import blogTwo from "@/assets/images/blog-2.jpg";
// import blogThree from "@/assets/images/blog-3.jpg";
import memberOne from "@/assets/images/team-1.jpg";
import memberTwo from "@/assets/images/team-2.jpg";
import memberThree from "@/assets/images/team-3.jpg";
import memberFour from "@/assets/images/team-4.jpg";
import BlogHomeOne from "../HomeOne/BlogHomeOne.vue";
// import FaqHomeOne from "../HomeOne/FaqHomeOne.vue";
import FeaturesHomeOne from "../HomeOne/FeaturesHomeOne.vue";
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import ProjectHomeOne from "../HomeOne/ProjectHomeOne.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
import TeamHomeOne from "../HomeOne/TeamHomeOne.vue";
import CounterHomeTwo from "../HomeTwo/CounterHomeTwo.vue";
import DownloadHomeTwo from "../HomeTwo/DownloadHomeTwo.vue";
// import PriceingHomeTwo from "../HomeTwo/PriceingHomeTwo.vue";
import HeaderHomeSeven from "./HeaderHomeSeven.vue";
import HeroHomeSeven from "./HeroHomeSeven.vue";
import menu from "@/components/menu";
import blog from "@/components/Blogs/blogs";
export default {
  components: {
    SidebarHomeOne,
    HeaderHomeSeven,
    HeroHomeSeven,
    FeaturesHomeOne,
    CounterHomeTwo,
    DownloadHomeTwo,
    TeamHomeOne,
    // PriceingHomeTwo,
    // FaqHomeOne,
    BlogHomeOne,
    ProjectHomeOne,
    FooterHomeOne,
  },
  data() {
    return {
      sidebar: false,
      blogs: blog,
      members: [
        {
          name: "TOSSOU Adotè Fabrice",
          position: "Developpement Frontend",
          img: memberOne,
          socials: [
            {
              name: "linkedin",
              url: "https://www.linkedin.com/in/fabrice-tossou/",
              icon: "fab fa-linkedin", //user font awesome class
            },
            {
              name: "gitlab",
              url: "https://gitlab.com/TOSFABRE",
              icon: "fab fa-gitlab", //user font awesome class
            },
            {
              name: "twitter",
              url: "https://twitter.com/tosfabre",
              icon: "fab fa-twitter", //user font awesome class
            },
          ],
        },
        {
          name: "ANSON Gontran",
          position: "Developpement Backend",
          img: memberTwo,
          socials: [
            {
              name: "linkedin",
              url: "https://www.linkedin.com/in/komla-fabrice-anson/",
              icon: "fab fa-linkedin", //user font awesome class
            },
            {
              name: "github",
              url: "https://github.com/darkmoon-dev",
              icon: "fab fa-github", //user font awesome class
            },
            {
              name: "twitter",
              url: "https://twitter.com/Gontran_dev",
              icon: "fab fa-twitter", //user font awesome class
            },
          ],
        },
        {
          name: "BEREKOU Abdou Rafiou",
          position: "UX Legal & UX Writing",
          img: memberThree,
          socials: [
            {
              name: "linkedin",
              url: "https://www.linkedin.com/in/berekou-abdou-rafiou/",
              icon: "fab fa-linkedin", //user font awesome class
            },
            {
              name: "medium",
              url: "https://medium.com/@berekouabdourafiou",
              icon: "fab fa-medium", //user font awesome class
            },
            /*{
              name: "pinterest",
              url: "https://www.pinterest.com/",
              icon: "fab fa-pinterest-p", //user font awesome class
            },*/
          ],
        },
        {
          name: "SALLAH Joan",
          position: "Designer",
          img: memberFour,
          socials: [
            {
              name: "instagram",
              url: "https://instagram.com/salh.std?igshid=OGQ5ZDc2ODk2ZA==",
              icon: "fab fa-instagram", //user font awesome class
            },
            {
              name: "behance",
              url: "https://www.behance.net/joansalh",
              icon: "fab fa-behance", //user font awesome class
            },
            {
              name: "linkedin",
              url: "https://www.linkedin.com/in/joan-sallah",
              icon: "fab fa-linkedin", //user font awesome class
            },
          ],
        },
      ],
      navs: menu/*[
        {
          name: "home",
          childrens: [
            {
              name: "Home 1",
              path: "/",
            },
            {
              name: "Home 2",
              path: "/home-two",
            },
            {
              name: "Home 3",
              path: "/home-three",
            },
            {
              name: "Home 4",
              path: "/home-four",
            },
            {
              name: "Home 5",
              path: "/home-five",
            },
            {
              name: "Home 6",
              path: "/home-six",
            },
            {
              name: "Accueil",
              path: "/home-seven",
            },
            {
              name: "Home 8",
              path: "/home-eight",
            },
            {
              name: "Home Dark",
              path: "/home-dark",
            },
            {
              name: "Home Rtl",
              path: "/home-rtl",
            },
          ],
        },
        {
          name: "service",
          path: "/service-details",
        },
        {
          name: "pages",
          childrens: [
            {
              name: "About us",
              path: "/about-us",
            },
            {
              name: "About us 2",
              path: "/about-two",
            },
            {
              name: "Shops",
              path: "/shops",
            },
            {
              name: "Shop Details",
              path: "/shop-details",
            },
            {
              name: "Error",
              path: "/error",
            },
          ],
        },
        {
          name: "news",
          childrens: [
            {
              name: "News",
              path: "/news",
            },
            {
              name: "Single news",
              path: "/single-news",
            },
          ],
        },
        {
          name: "contact",
          path: "/contact",
        },
      ]*/,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>
