export default [
    {
        name: "accueil",
        path: "/accueil"
    },
    {
        name: "Services",
        childrens: [
            {
                name: "fonctionnalités",
                path: "/fonctionnalites"
            },
            {
                name: "Pharmacies",
                path: "/pharmacies"
            },
            {
                name: "médicaments",
                path: "/medicaments"
            },
            {
                name: "API",
                path: "/api"
            },
        ]
    },
    {
        name: "gardePharma",
        childrens: [
            {
                name: "à propos",
                path: "/a-propos",
            },
            {
                name: "trouver médecin",
                path: "/trouver-medecin",
            },
        ]
    },
    {
        name: "Blog",
        childrens: [
            {
                name: "tous",
                path: "/news",
            },
            {
                name: "maladie",
                path: "/news",
            },
            {
                name: "nutrition",
                path: "/news",
            },
        ],
    },
    {
        name: "contact",
        path: "/contact",
    }
];
