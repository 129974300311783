<template>
      <!--====== APPIE SERRVICE 2 PART START ======-->
    
    <section class="appie-services-2-area pb-100" id="service">
        <div class="container">
            <div class="row align-items-end">
                <div class="col-lg-6 col-md-8">
                    <div class="appie-section-title">
                        <h3 class="appie-title">{{title}}</h3>
                        <p>{{description}}</p>
                    </div>
                </div>
                <!--<div class="col-lg-6 col-md-4">
                    <div class="appie-section-title text-right">
                        <a class="main-btn" href="#">View all Features <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>-->
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fas fa-crosshairs"></i>
                        </div>
                        <h4 class="title">Géolocalisation</h4>
                        <p>Nous nous proposons des pharmacies par rapport à votre géolocalisation. En activant votre géolocalisation, vous pourrez trouver facilement des pharmacies de garde ou pas, , vous pourrez trouver facilement des pharmacies de garde ou pas, selon votre désir.</p>
                        <!--<a href="#">Lire plus <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fas fa-location-arrow"></i>
                        </div>
                        <h4 class="title">Près de...</h4>
                        <p>De façon plus aiguisée, nous vous permettons de trouver des pharmacies par rapport à un point précis de votre choix. Que vous soyez à la maison, au bureau ou en déplacement, nous cherchons à rendre votre expérience de santé aussi simple que possible ceci en un Click!</p>
                        <!--<a href="#">Lire plus <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fas fa-list"></i>
                        </div>
                        <h4 class="title">Liste de pharmacie</h4>
                        <p>La liste des pharmacies par ordre alphabétique avec leur contact et détails géographiques vous aidera à prévoir votre prochaine visite ou à les contacter directement pour vos besoins spécifiques. Avec cette liste facile à utiliser, plus besoin de chercher au hasard ou passer des heures sur Internet pour trouver une pharmacie proche de chez vous.</p>
                        <!--<a href="#">Lire plus <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fas fa-prescription-bottle"></i>
                        </div>
                        <h4 class="title">Simulation d'ordonnace</h4>
                        <p>Grâce à notre outil de simulation d'ordonnance en ligne, vous pouvez avoir une idée claire du coût de vos médicaments prescrits avant même de quitter votre maison. Savoir ce qui est inclus dans votre ordonnance vous permet de gérer de manière plus efficace votre budget-santé. En outre, cela vous aide à mieux comprendre et à participer activement à l’identification de votre budget-santé.</p>
                        <!--<a href="#">Lire plus <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fas fa-search"></i>
                        </div>
                        <h4 class="title">Recherche de Pharmacie et de médicament</h4>
                        <p>Sur Garde Pharma, une simple recherche vous permet de trouver rapidement la pharmacie la plus proche et si les médicaments dont vous avez besoin sont disponibles.
                          Nous visons à être plus qu'un simple outil de localisation ou gestionnaire d'ordonnance en ligne. Nous voulons devenir votre allié privilégié pour tout ce qui concerne vos besoins en matière de santé.
                        </p>
                        <!--<a href="#">Lire plus <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="appie-single-service-2 item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fas fa-calendar"></i>
                        </div>
                        <h4 class="title">Agenda et Notification</h4>
                        <p>Avec notre fonction d'agenda, vous pouvez voir quelles sont les pharmacies ouvertes par jour spécifique, ce qui facilite grandement la planification de vos visites en pharmacie. De plus, grâce aux notifications, vous serez toujours au courant des actualités ou de changements liés à votre pharmacie préférée ou concernant vos médicaments génériques.</p>
                        <!--<a href="#">Lire plus <i class="fal fa-arrow-right"></i></a>-->
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE SERRVICE 2 PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        description:{
            type:String,
        }
    }

}
</script>

<style>

</style>
