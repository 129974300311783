<template>
  <div>
    <BlankPage title="Politique de confidentialite" :breadcrumbs="[{name:'Politique de confidentialite', path:'/privacy-policy'}]">
      <div>
        <p style="text-align: justify;">Cette politique de confidentialité s'applique à l'application mobile <strong>Garde Pharma</strong> (puisque <strong>Garde Pharma</strong> est une application mobile pour l’instant) et fournit des informations au public sur les pratiques de confidentialité mises en œuvre par l'application. Nous reconnaissons l'importance de protéger la confidentialité de vos informations personnelles et nous sommes engagés à le faire.</p>
        <br><p style="text-align: justify;">Cette politique de confidentialité décrit les pratiques de collecte, d'utilisation et de divulgation des informations personnelles par le biais de cette application.</p>
        <br><p style="text-align: justify;">De ce fait, si vous utilisez l'application <strong>Garde Pharma</strong>, vous acceptez et serez lié par les termes de cette politique de confidentialité.</p>
        <br>
        <ol start="1" class="mb-2">
          <li><strong>1. Généralité </strong></li>
        </ol>
        <p style="text-align: justify;"><strong>Garde Pharma</strong> est une application mobile destinée à aider à toute personne à géolocaliser, à trouver des médicaments et des informations pertinentes pour tout achat de produits. L'application collecte certaines informations sur les utilisateurs, afin de fournir les services. <strong>Garde Pharma</strong> traitera vos informations conformément à cette politique de confidentialité et à la loi applicable (Loi n° 2019-014 relative à la protection des données à caractère personnel).</p>
        <br>
        <ol start="2" class="mb-2">
          <li><strong>2. Collecte et traitement des données</strong></li>
        </ol>
        <p style="text-align: justify;">Lors de l'utilisation de l'application<strong> Garde Pharma</strong>, vous êtes invitée à fournir l'information suivante :</p>
        <ul>
          <li>Localisation</li>
        </ul>
        <p style="text-align: justify;">Nous utilisons cette information pour fournir des services et gérer votre navigation et vous envoyer des informations pertinentes ceci par rapport à votre localisation ou au point choisi. Cette utilisation commence par une action déclenchée (ici votre demande d’accéder à la liste des pharmacies) par l'utilisateur dans l'application ; et prend fin dès que vous avez jugé le résultat satisfaisant.</p>
        <br>
        <ol start="3" class="mb-2">
          <li><strong>3. Sécurité</strong></li>
        </ol>
        <p style="text-align: justify;"><strong>Garde Pharma</strong> a mis en place des procédures de sécurité pour protéger les informations personnelles d'utilisation. Les informations sont conservées sur des serveurs sécurisés et sont protégées par des mesures physiques, électroniques et administratives</p>
        <br>
        <ol start="4" class="mb-2">
          <li><strong>4. Droits d’utilisation</strong></li>
        </ol>
        <p style="text-align: justify;">En cas de questions ou pour plus d'informations sur la politique de confidentialité, veuillez envoyer un e-mail à l'équipe de <strong>Garde Pharma</strong> à l'adresse suivante : <a href="mailto:tosfabre@gmail.com">tosfabre@gmail.com</a></p>
        <br><p style="text-align: justify;">Les utilisateurs ont le droit de modifier ou de supprimer leurs informations à tout moment.</p>
        <p style="text-align: justify;">Les utilisateurices peuvent de plus demander à ce que leurs informations soient modifiées, supprimées ou limitées dans l'utilisation qu'en fait <strong>Garde Pharma</strong> et de demander à ce que cela soit faite. Cette demande prend un délai maximum de 05 jours ouverts. Les utilisateurices peuvent également s’opposer au traitement des informations, et demander à recevoir les informations sous forme d’une copie électronique. Les demandes doivent être adressées à l'adresse suivante et prendront un délai maximum de 15 jours : <a href="mailto:tosfabre@gmail.com">tosfabre@gmail.com</a></p>
        <br>
        <ol start="5" class="mb-2">
          <li><strong>5. Changements apportés à la politique de confidentialité</strong></li>
        </ol>
        <p style="text-align: justify;"><strong>Garde Pharma</strong> peut modifier cette politique de temps en temps pour refléter l'évolution des pratiques de confidentialité. Les modifications seront affichées sur cette page et entrées en vigueur immédiatement. Nous vous encourageons à vérifier cette page régulièrement pour vous assurer que vous êtes toujours au courant des modifications apportées.</p>
      </div>
    </BlankPage>
  </div>
</template>

<script>
import BlankPage from "@/components/BlankPage/index.vue";

export default {
  components: {
    BlankPage
  }
}
</script>

<style>

</style>
