<template>
  <div>
    <BlankPage title="Fonctionnalités" :breadcrumbs="[{name:'Services', path:'#'}, {name:'Fonctionnalités', path:'/functionalities'}]">
      <!--====== APPIE SERRVICE 2 PART START ======-->

      <services-home-two
        title="Comment ça marche !"
        description="Jusqu’à ce que votre satisfaction ne fusse TOTALE"
      />
      <!--====== APPIE SERRVICE 2 PART ENDS ======-->
    </BlankPage>
  </div>
</template>

<script>
import BlankPage from "@/components/BlankPage/index.vue";
import ServicesHomeTwo from "@/components/HomeTwo/ServicesHomeTwo.vue";

export default {
  components: {
    ServicesHomeTwo,
    BlankPage
  }
}
</script>

<style>

</style>
