<template>
  <div>
    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one
        :sidebar="sidebar"
        @toggleSidebar="toggleSidebar"
        :menuItems="navs"
    />
    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <header-contact @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== PART ENDS ======-->

    <!--====== APPIE PAGE TITLE PART START ======-->

    <!-- reference by news -->
    <title-area :title="title" :breadcrumbs="breadcrumbs" />

    <!--====== APPIE PAGE TITLE PART ENDS ======-->

    <section class="blogpage-section">
      <div class="container">
        <slot></slot>
      </div>
    </section>

    <!--====== APPIE FOOTER PART START ======-->

    <footer-home-one />

    <!--====== APPIE FOOTER PART ENDS ======-->

    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>


import HeaderContact from "@/components/Contact/HeaderContact.vue";
import SidebarHomeOne from "@/components/HomeOne/SidebarHomeOne.vue";
import menu from "@/components/menu";
import TitleArea from "@/components/News/TitleArea.vue";
import FooterHomeOne from "@/components/HomeOne/FooterHomeOne.vue";

export default {
  props:{
    title:{
      type:String,
    },
    breadcrumbs: {
      type:Array,
      default: () =>{
        return []
      }
    }
  },
  components: {
    FooterHomeOne,
    TitleArea,
    HeaderContact,
    SidebarHomeOne
  },
  data() {
    return {
      sidebar: false,
      navs: menu,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  }
}
</script>

<style scoped>

</style>
